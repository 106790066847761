import type { StateParameter } from '@arnold/common';
import { jsonToBase64 } from '@arnold/common/utils';

import { getUserLocaleFromAvailableLocales } from '../locale';
import type { RuntimeConfig } from '../types';
import { TENANT_QUERY_PARAM } from '../utils/constants';
import { getActiveTenantId } from './session.provider';
import { getAuthorizationEndpoint } from './zeiss-id-discovery';

export function zeissIdLogin(config: RuntimeConfig): Promise<any> {
  const locale = getUserLocaleFromAvailableLocales(config.availableLocales);

  return getAuthorizationEndpoint(config)
    .then((url) => createAuthorizationRequest(url, locale, config))
    .then((url) => redirectToLogin(url));
}

function createAuthorizationRequest(
  authorizationUrl: string,
  userLocale: string,
  config: RuntimeConfig
): string {
  const url = new URL(authorizationUrl);

  url.searchParams.append('client_id', config.auth.clientId);
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('response_mode', 'form_post');
  url.searchParams.append(
    'redirect_uri',
    `${location.origin}${config.endpoints.auth.redirectUriPath}`
  );

  const redirectUrlAfterLogin = encodeURIComponent(location.href);
  const tenant = getTenantFromUrl() || getActiveTenantId();
  const state: StateParameter = { redirectUrlAfterLogin, ...(tenant && { tenant }) };
  url.searchParams.append('state', jsonToBase64(state));

  url.searchParams.append('scope', `${config.auth.clientId} offline_access openid`);

  // pass userLocale to URL to ensure that ZEISS ID loads the sign in template in the corresponding language
  url.searchParams.append('ui_locale', userLocale);

  return url.toString();
}

function getTenantFromUrl() {
  const tenant = new URLSearchParams(location.search).get(TENANT_QUERY_PARAM);
  return tenant && Number(tenant);
}

function redirectToLogin(url: string): Promise<void> {
  location.href = url;

  // We are deliberately not resolving the promise in order to let the redirect run to completion as it is not instant
  return Promise.resolve();
}
