import { getPublicPath } from '../environment';
import { getUserData } from './authenticated-user';

export function redirectUnauthenticatedUserToLogin(): void {
  if (!userLoggedIn()) {
    location.href = `${location.origin}${getPublicPath()}`;
  }
}

export function userShouldAuthenticate(): boolean {
  return !userLoggedIn() && isRestrictedPage();
}

function userLoggedIn() {
  return getUserData() !== undefined;
}

function isRestrictedPage(): boolean {
  return !location.pathname.startsWith(`${getPublicPath()}public`);
}
