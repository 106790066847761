import type { PiralPlugin } from 'piral-core';

import { getSessionData } from '../../auth/authenticated-user';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletHasApplicationApi {}
}

export interface PiletHasApplicationApi {
  /**
   * Returns true when the authenticated user is assigned to the application based on:
   * - ZEISS ID Client Applications in case of a Freemium user,
   * - ZEISS ID Agreement Applications in case of an Agreement user, or
   * - assigned licenses in case of a Subscription user.
   *
   * @param {string} appName: the HDP name of the checked application. I.e. "hdp.zls"
   */
  hasApplication(appName: string): boolean;
}

export function createHasApplicationApi(): PiralPlugin<PiletHasApplicationApi> {
  return () => () => ({
    hasApplication(appName) {
      const sessionData = getSessionData();

      if (!sessionData || !sessionData.availableAppNames) {
        return false;
      }

      return sessionData.availableAppNames.includes(appName);
    },
  });
}
