import { TENANT_SWITCH_CHANNEL_NAME } from './constants';
import { navigateToHomepage } from './navigation';

const tenantSwitchChannel = new BroadcastChannel(TENANT_SWITCH_CHANNEL_NAME);

export function registerTenantSync(): void {
  tenantSwitchChannel.onmessage = (event: MessageEvent) => {
    navigateToHomepage(event.data);
  };
}

export function switchMultiTabTenant(tenantId: number): void {
  tenantSwitchChannel.postMessage(tenantId);
}
