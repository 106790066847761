import { getPublicPath } from '../environment';
import { instructions } from '../routes';
import { ClientType } from './client';

export const redirectPubliclyAccessibleRoutes = (clientType: ClientType): void => {
  if (clientType === ClientType.MobileBrowser) {
    navigateToInstructionsPage();
  }
};

const navigateToInstructionsPage = (): void => {
  if (location.pathname !== `${getPublicPath()}${instructions.substring(1)}`) {
    location.href = `${location.origin}${getPublicPath()}${instructions.substring(1)}`;
  }
};
