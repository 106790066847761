import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
:root {
  --arn-space-1: calc(var(--zui-gu) * 0.5);
  --arn-space-2: var(--zui-gu);
  --arn-space-3: calc(var(--zui-gu) * 1.5);
  --arn-space-4: calc(var(--zui-gu) * 2);
  --arn-space-6: calc(var(--zui-gu) * 3);
  --arn-space-8: calc(var(--zui-gu) * 4);
  --arn-space-9: calc(var(--zui-gu) * 4.5);
  --arn-space-10: calc(var(--zui-gu) * 5);
  --arn-space-12: calc(var(--zui-gu) * 6);
  --arn-space-14: calc(var(--zui-gu) * 7);
  --arn-space-14_5: calc(var(--zui-gu) * 7.25);
  --arn-space-16: calc(var(--zui-gu) * 8);
  --arn-space-18: calc(var(--zui-gu) * 9);
  --arn-space-20: calc(var(--zui-gu) * 10);
  --arn-space-24: calc(var(--zui-gu) * 12);
  --arn-space-28: calc(var(--zui-gu) * 14);
  --arn-space-36: calc(var(--zui-gu) * 18);
  --arn-space-49: calc(var(--zui-gu) * 24.5);
  --arn-space-56: calc(var(--zui-gu) * 28);
  --arn-space-80: calc(var(--zui-gu) * 40);
  --arn-space-84: calc(var(--zui-gu) * 42);
  --arn-space-100: calc(var(--zui-gu) * 50);
  --arn-space-135: calc(var(--zui-gu) * 67.5);
  --arn-space-200: calc(var(--zui-gu) * 100);
  --arn-content-height: calc(100vh - var(--arn-space-20));
  --arn-animation-quick: 200ms;

  /* global variables for PWA until we can use ZUi mobile components */
  --arn-space-pwa-1: calc(var(--arn-space-pwa-2) * 0.5);
  --arn-space-pwa-2: 8px; 
  --arn-space-pwa-4: calc(var(--arn-space-pwa-2) * 2);
  --arn-space-pwa-6: calc(var(--arn-space-pwa-2) * 3);
  --arn-space-pwa-8: calc(var(--arn-space-pwa-2) * 4);
  --arn-space-pwa-10: calc(var(--arn-space-pwa-2) * 5);
  --arn-space-pwa-12: calc(var(--arn-space-pwa-2) * 6);
  --arn-space-pwa-16: calc(var(--arn-space-pwa-2) * 8);
  --arn-space-pwa-20: calc(var(--arn-space-pwa-2) * 10);
  --arn-space-pwa-24: calc(var(--arn-space-pwa-2) * 12);
  --arn-space-pwa-40: calc(var(--arn-space-pwa-2) * 20);

  --arn-color-pwa-gs-10: #FFF;
  --arn-color-pwa-gs-20: #F5F8FA;
  --arn-color-pwa-gs-30: #EDF2F7;
  --arn-color-pwa-gs-40: #DDE3ED;
  --arn-color-pwa-gs-60: #C1CBD9;
  --arn-color-pwa-gs-85: #606A76;
  --arn-color-pwa-gs-100: #353D45;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--zui-font-family);
}

#app {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;

  * {
  box-sizing: border-box;
  }
}

.app-page {
  position: absolute;
  overflow: hidden;
  inset: 0;
  overflow-y: auto;
}

.app-auto-scrolled-settings-page {
  height: 100%;
  overflow-y: auto;
}
`;

export default GlobalStyle;
