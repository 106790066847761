import type { PiralPlugin } from 'piral-core';

import { getUserData } from '../../auth';
import { APP_SHELL_IDENTIFIER } from '../../tracking/init';
import type { RuntimeConfig, TrackableEvent, TrackingObject } from '../../types';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletTrackingApi {}
}

export interface PiletTrackingApi {
  /**
   * Allows pilets to send their custom tracking events
   * @param trackingObject object that contains tracking data coming from the pilets.
   */
  sendTrackingEvent(trackingObject: TrackingObject): void;
}

export function createTrackingPlugin(config: RuntimeConfig): PiralPlugin<PiletTrackingApi> {
  return () => () => ({
    sendTrackingEvent(trackingObject) {
      if (!config.tracking.enabled) {
        return;
      }

      const accountId = getUserData()?.accountId;

      const trackableEvent: TrackableEvent = {
        ...trackingObject,
        event: 'event',
        contentHierarchy1: APP_SHELL_IDENTIFIER,
        userId: accountId?.toString(),
        userLoginState: Boolean(accountId),
      };
      window.dataLayer.push(trackableEvent);
    },
  });
}
