import { changeConsentLanguage } from '../consent';
import { getLocalStorageItem, setLocalStorageItem, StorageKey } from '../utils/storage';

export function getUserDefinedLocale(): string | null {
  return getLocalStorageItem(StorageKey.BROWSER_LOCALE);
}

export function setUserDefinedLocale(locale: string): void {
  setLocalStorageItem(StorageKey.BROWSER_LOCALE, locale);

  const languageChangeFromSettingsEvent = new CustomEvent('languageChangeFromSettings', {
    detail: locale,
  });

  window.dispatchEvent(languageChangeFromSettingsEvent);
  changeConsentLanguage(locale);
}
