import { match } from '@formatjs/intl-localematcher';

import { getUserDefinedLocale } from './intl/user-defined-locale';
import type { LanguageLoader } from './plugins/intl/plugin';
import type { RuntimeConfig } from './types';

export function loadLocales(): LanguageLoader {
  const loadTranslations = require.context('./locales', true, /.json$/, 'lazy');
  return (locale) => loadTranslations(`./${locale}.json`);
}

export function getBrowserLocaleFromAvailableLocales(
  availableLocales: RuntimeConfig['availableLocales']
): string {
  return match([navigator.language], availableLocales, DEFAULT_LOCALE);
}

export function getUserLocaleFromAvailableLocales(
  availableLocales: RuntimeConfig['availableLocales']
): string {
  return getUserDefinedLocale() ?? getBrowserLocaleFromAvailableLocales(availableLocales);
}

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_LEGAL_LANGUAGE = DEFAULT_LOCALE;
