import { reactify, ZuiHomeScreenTile } from '@zeiss/zui-react';
import { usePiletApi } from 'piral-core';
import { type FC } from 'react';

import { useContent } from '../hooks/use-content';
import { useNavAction } from '../hooks/use-nav-action';
import { EventAction, EventName, type HomepageTileProps, ModuleType } from '../types';

export const Tile: FC<HomepageTileProps> = ({
  icon,
  title,
  subtitle,
  href,
  callback,
  trackingName,
}) => {
  const Icon = reactify(icon);
  const tileTitle = useContent(title);
  const tileSubtitle = subtitle ? useContent(subtitle) : '';
  const action = useNavAction(href, callback);
  const api = usePiletApi();

  const trackableEvent = {
    eventAction: EventAction.CLICK,
    eventName: EventName.NAVIGATION,
    eventValue: trackingName ?? `Tile: ${href}`,
    eventDetail: href,
    moduleType: ModuleType.NAVIGATION_TILES,
    contentHierarchy2: 'Home',
    contentHierarchy4: 'Applications',
  };

  const handleAndTrackAction = () => {
    if (href) {
      api.sendTrackingEvent(trackableEvent);
    }

    action();
  };

  return (
    <ZuiHomeScreenTile
      size="l"
      tileTitle={tileTitle}
      tileSubtitle={tileSubtitle}
      onClick={handleAndTrackAction}
    >
      <Icon slot="icon" />
    </ZuiHomeScreenTile>
  );
};
