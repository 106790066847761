import type { RuntimeConfig } from '../types';
import { TENANT_QUERY_PARAM } from '../utils/constants';
import { fetchJSON } from '../utils/fetch-json';
import { cancelSessionRefreshStream } from '../utils/session-refresh';
import { resetStorageData } from './session.provider';
import { getLogoutEndpoint } from './zeiss-id-discovery';

export const logout = async (config: RuntimeConfig): Promise<void> => {
  try {
    await fetchJSON<void>(config.endpoints.auth.logout, { method: 'POST' });
  } finally {
    await endSession(config);
  }
};

export const endSession = async (config: RuntimeConfig): Promise<void> => {
  cancelSessionRefreshStream();
  const endSessionUrl = new URL(await getLogoutEndpoint(config));
  const url = new URL(location.href);
  url.searchParams.delete(TENANT_QUERY_PARAM);
  endSessionUrl.searchParams.set('post_logout_redirect_uri', url.toString());
  resetStorageData();
  location.replace(endSessionUrl.toString());
};
