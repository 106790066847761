import { getSessionData } from '../auth';
import { getUserLocaleFromAvailableLocales } from '../locale';
import type { AppConfig, DataLayerBase } from '../types';
import { gtmSnippets } from './gtmSnippets';

export const APP_SHELL_IDENTIFIER = 'HDP App Shell';
const UNSET_COUNTRY = 'UNSET';

function installTrackingScript(config: AppConfig) {
  const dataLayerBase: DataLayerBase = {
    pageArea: 'web',
    pageCountry: getSessionData()?.country || UNSET_COUNTRY,
    pageEnvironment: config.tracking.environment,
    pageIdentifier: APP_SHELL_IDENTIFIER,
    pageLanguage: getUserLocaleFromAvailableLocales(config.availableLocales),
    pageName: 'Home',
    pagePool: 'med',
    contentHierarchy1: APP_SHELL_IDENTIFIER,
  };

  const dataLayerScriptTag = document.createElement('script');
  dataLayerScriptTag.innerHTML = gtmSnippets.dataLayer(dataLayerBase);

  const installFunctionScriptTag = document.createElement('script');
  installFunctionScriptTag.innerHTML = gtmSnippets.installFunction(config.tracking.gtmId);

  const noScriptTag = document.createElement('noscript');
  noScriptTag.innerHTML = gtmSnippets.noScriptIframe(config.tracking.gtmId);

  document.head.prepend(dataLayerScriptTag);
  document.head.insertBefore(installFunctionScriptTag, dataLayerScriptTag.nextSibling);
  document.body.prepend(noScriptTag);
}

function changeTrackingLocale(locale: string): void {
  // First element is always the base config we need to modify
  (window.dataLayer[0] as DataLayerBase).pageLanguage = locale;
}

function setupLanguageChangeListeners(availableLocales: AppConfig['availableLocales']) {
  window.addEventListener('languagechange', () => {
    changeTrackingLocale(getUserLocaleFromAvailableLocales(availableLocales));
  });

  window.addEventListener('languageChangeFromSettings', (e) => {
    const language = (e as CustomEvent).detail;
    changeTrackingLocale(language);
  });
}

export function setupTracking(config: AppConfig): void {
  installTrackingScript(config);
  setupLanguageChangeListeners(config.availableLocales);
}
