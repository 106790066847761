import type { HdpApp, LayoutConfig } from '../types';
import { ClientType } from '../utils/client';

const importLayoutFactory = (type: ClientType) => {
  switch (type) {
    case ClientType.MobilePwa:
      return import('./layout-pwa-lean');
    case ClientType.MobileBrowser:
      return import('./layout-pwa-instructions');
    case ClientType.Desktop:
    default:
      return import('./layout-full');
  }
};

export const createLayout = async (app: HdpApp, clientType: ClientType): Promise<LayoutConfig> => {
  const { makeLayout } = await importLayoutFactory(clientType);

  return makeLayout(app);
};
