import type { PiletMetadata } from 'piral-core';

import type { AppShellType, FeedConfig } from '../types';

interface FeedResponse {
  items: Array<PiletMetadata>;
}

export async function fetchPilets(urls: Array<string>): Promise<Array<PiletMetadata>> {
  const items = await Promise.all(
    urls.map(async (url) => {
      const res = await fetch(url);

      if (res.ok) {
        const data: FeedResponse = await res.json();
        return data.items;
      }

      if (res.status === 404) {
        // in case of a feed not found we should just return an empty array
        return [];
      }

      throw new Error(`Failed to fetch pilets from ${url}: ${res.status} - ${res.statusText}`);
    })
  );

  return items.flat();
}

export function getFeeds(feeds: FeedConfig, type: AppShellType): Array<string> {
  if (Array.isArray(feeds)) {
    return type === 'full' ? feeds : [];
  }

  if (type === 'static') {
    return [];
  }

  if (feeds) {
    const typeFeeds = feeds[type];

    if (typeFeeds && Array.isArray(typeFeeds)) {
      return typeFeeds;
    }
  }

  return [];
}

export function createFeedUrls(
  environment: string,
  feedEndpoint: string,
  feeds: Array<string>
): Array<string> {
  const searchParams = new URLSearchParams(location.search);

  return feeds.map((feed) => {
    const url = `${feedEndpoint}/${environment}-${feed}`;
    return searchParams.has(feed) ? `${url}?tag=${searchParams.get(feed)}` : url;
  });
}
