export enum EventAction {
  CLICK = 'Click',
}

export enum EventName {
  NAVIGATION = 'Navigation',
}

export enum ModuleType {
  NAVIGATION_TILES = 'Navigation Tiles',
  NAVIGATION_BAR = 'Navigation Bar',
}

export interface DataLayerBase {
  pageArea: string;
  pageCountry: string;
  pageEnvironment: string;
  pageIdentifier: string;
  pageLanguage: string;
  pageName: string;
  pagePool: string;
  contentHierarchy1: string;
}

/**
 * Generic interface of a trackable event.
 */
export interface TrackableEvent {
  /**
   * Static property to distinct the manually fired events. (Value is always "event")
   */
  event: 'event';

  eventCategory?: string;

  /**
   * The action that triggered the event
   */
  eventAction: string;
  eventLabel?: string;

  /**
   * The name that describes the purpose of the event
   */
  eventName: string;
  eventType?: string;

  /**
   * The text associated with the event, e.g.: the label of a button
   */
  eventValue: string;

  /**
   * Specifying detail associated with the event, e.g.: the href of a link
   */
  eventDetail: string | undefined;

  /**
   * Abstract type of the interacted item (e.g. checklist, dropdown, article)
   */
  moduleType: string;
  moduleSection?: string;

  /**
   * Hierarchical description of the event, higher numbers signify more specific child elements
   */
  contentHierarchy1?: string;
  contentHierarchy2?: string;
  contentHierarchy3?: string;
  contentHierarchy4?: string;
  contentHierarchy5?: string;
  product?: string;

  /**
   * The ZeissID of the user, if logged in.
   */
  userId: string | undefined;

  /**
   * Describes if the user is logged in or not.
   */
  userLoginState: boolean;
}

export type TrackingObject = Omit<
  TrackableEvent,
  'event' | 'contentHierarchy1' | 'userId' | 'userLoginState'
>;
