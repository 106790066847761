import { reactify, ZuiMenubarNavItem } from '@zeiss/zui-react';
import { usePiletApi } from 'piral-core';
import { type FC, useMemo } from 'react';

import { useEmphasis } from '../hooks/use-emphasis';
import { useNavAction } from '../hooks/use-nav-action';
import { EventAction, EventName, type MenuBarItemProps, ModuleType } from '../types';
import { getContent } from '../utils/content';

// 459722 bugfix - solution to provide unique value<string> for the ZuiMenubarNavItem 'value' attribute
let uniqueValue = 0;

export const MenuBarItem: FC<MenuBarItemProps> = ({
  href,
  icon,
  callback,
  title,
  trackingName,
}) => {
  const emphasis = useEmphasis(href);
  const Icon = reactify(icon);
  const action = useNavAction(href, callback);
  const value = useMemo(() => `${uniqueValue++}`, []);
  const api = usePiletApi();

  const trackableEvent = {
    eventAction: EventAction.CLICK,
    eventName: EventName.NAVIGATION,
    eventValue: trackingName ?? `Sidebar: ${href}`,
    eventDetail: href,
    moduleType: ModuleType.NAVIGATION_BAR,
    contentHierarchy2: 'Home',
  };

  const handleAndTrackAction = () => {
    if (href) {
      api.sendTrackingEvent(trackableEvent);
    }

    action();
  };

  return (
    <ZuiMenubarNavItem emphasis={emphasis} onClick={handleAndTrackAction} value={value}>
      <Icon size="s16" slot="icon" />
      {getContent(title)}
    </ZuiMenubarNavItem>
  );
};
