import { getSessionData } from './auth';
import { getPublicPath } from './environment';
import { setupI18next } from './intl/i18n';
import { getLegalLanguage } from './intl/legal-language';
import {
  DEFAULT_LOCALE,
  getBrowserLocaleFromAvailableLocales,
  getUserLocaleFromAvailableLocales,
  loadLocales,
} from './locale';
import type { HdpApp, RuntimeConfig } from './types';

export function createApp(config: RuntimeConfig): HdpApp {
  const locale = DEFAULT_LOCALE;
  const basePath = getPublicPath();
  const country = getSessionData()?.country || 'default';
  const defaultLoader = loadLocales();
  const getBrowserLocale = () => getBrowserLocaleFromAvailableLocales(config.availableLocales);
  const getUserLocale = () => getUserLocaleFromAvailableLocales(config.availableLocales);
  const ifuLanguage = getLegalLanguage(country, config.legalLanguages, getUserLocale());

  return {
    basePath,
    config,
    title: 'HDP App Shell',
    locale,
    ifuLanguage,
    country,
    languageProvider: setupI18next(defaultLoader, locale),
    getUserLocale,
    getBrowserLocale,
  };
}
